import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import UiKitProvider from "../libs/uikit/UiKitProvider";

type Env = {
  SENTRY_DSN: string;
};

declare global {
  let ENV: Env;
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    ENV: Env;
  }
}

try {
  process.env.NODE_ENV !== "development" &&
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      tracesSampleRate: 1,

      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
        Sentry.consoleIntegration(),
      ],
    });
} catch (err) {}

// @from: https://github.com/facebook/react/issues/24430#issuecomment-1156537554
document.querySelectorAll("html > script, html > input").forEach((s) => {
  s.parentNode?.removeChild(s);
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <UiKitProvider>
        <RemixBrowser />
      </UiKitProvider>
    </StrictMode>
  );
});
